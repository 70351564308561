//@flow
import type { RequestType } from "../request-type";
import type { TokenServiceInterface } from "../token";
import type { BaseServiceResponse } from "./BaseServiceResponse";
import type { BaseManagerResponse } from "./BaseManagerResponse";
import type { ConnectivityInterface } from "../connectivity";
import BaseService from "./BaseService";
import { getRootStore } from "../../store/root/RootStore";
import { fetchProviderInstance } from "./FetchProvider";
import { ServiceError } from "../error";
import { handleOnTokenExpired } from "./TokenExpiredHandler";
import { RequestTypes } from "../request-type/RequestType";
import * as constants from "../../constants";

function getTokenService(): TokenServiceInterface {
  return {
    getToken: () => {
      let state = getRootStore().getState();
      return state.authenticationStore.token;
    }
  };
}

function getConnectivity(): ConnectivityInterface {
  return {
    isConnected: () => getRootStore().getState().connectivityStore.isConnected
  }
}

let navigateToLogin = () => {
  handleOnTokenExpired();
};

export default class BaseManager {
  service: BaseService;
  mainUrl: string;
  mainDomain: string;

  constructor() {
    //TODO - need to switch domains based on build type, flavors/schemes
    this.service = new BaseService(getConnectivity());
    let developmentDomain = "https://stage.usz-cockpit.dpdev.ch/";
    let liveDomain = "https://backend.uszcockpit.ch/";
    //using the same domains for mobile and for web as well; bacause that's how SSO login with Microsoft will work
    this.mainDomain = liveDomain;//constants.DeviceType.IS_SMARTPHONE_OR_TABLET ? developmentDomain : developmentDomain;
    this.mainUrl = this.mainDomain + "api/";
  }

  createFullUrl(path: string): string {
    return this.mainUrl + path;
  }
  createURL(path) {
    return this.mainDomain + path + "/";
  }

  getToken(): string {
    return getTokenService().getToken();
  }

  async fetchHTML(
    path: string
  ): Promise<ServiceError | String> {
    let response: BaseServiceResponse = await this.service.makeRequest(
      RequestTypes.GET,
      path,
      this.getToken(),
      fetchProviderInstance,
    );
    if (response instanceof Response) {
      let resp = await response.text();
      return resp;
    } else {
      if (response instanceof ServiceError && (response.httpStatusCode === 401)) {
        let wasLoggedIn = this.getToken() != null;
        if (wasLoggedIn) {
          navigateToLogin();
        }
      }
      return response;
    }
  }

  async fetchJSON(
    requestType: RequestType,
    path: string,
    body: ?Object
  ): Promise<BaseManagerResponse> {
    let url = this.createFullUrl(path);
    let response: BaseServiceResponse = await this.service.makeRequest(
      requestType,
      url,
      this.getToken(),
      fetchProviderInstance,
      body
    );
    if (response instanceof Response) {
      let json = await response.json();
      return json;
    } else {
      if (response instanceof ServiceError && (response.httpStatusCode === 401)) {
        //The problem here is that, if the token expired => token!==null but user still has to be redirected to login screen
        //Also user has to be redirected to login screen when token===null 
        let wasLoggedIn = this.getToken() != null;
        if (!this.getToken() || wasLoggedIn) {
          navigateToLogin();
        }
      }
      return response;
    }
  }
}
