//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Logout.css";
import data from "../../data";
import { constants, store } from "cockpit-shared";
import { H2, H4 } from "../texts";
import { Tappable } from "../tappable";


type LogoutProps = {
  emailAddress: string,
  userName: string
};

type LogoutState = {
  onLogout: Function
};

class Logout extends Component<LogoutProps, LogoutState> {

  onLogoutTap = () => {
    this.props.onLogout();
  }

  render() {
    return (
      <div className="logout-main-container" >
        <div className="logout-user-info-container">
          <div className="logout-user-detail-container">
            <H4 style={{ color: constants.colors.gray, fontWeight: "bold", marginBottom: 10 }}>
              {data.strings.stringsRepo.emailAddress.toUpperCase()}
            </H4>
            <H2 style={{ color: constants.colors.w_veryDarkBlue }}>
              {this.props.emailAddress}
            </H2>
          </div>
          <div className="logout-user-detail-container">
            <H4 style={{ color: constants.colors.gray, fontWeight: "bold", marginBottom: 10 }}>
              {data.strings.stringsRepo.userName.toUpperCase()}
            </H4>
            <H2 style={{ color: constants.colors.w_veryDarkBlue }}>
              {this.props.userName}
            </H2>
          </div>
        </div>
        {
          // <div className="logout-horosontal-separator" />

          // <div className="logout-logout-container">
          //   <Link to="/login" style={{ textDecoration: "none" }}>
          //     <Tappable onTap={this.onLogoutTap}>
          //       <H2 style={{ color: constants.colors.w_veryDarkBlue }}>
          //         {data.strings.stringsRepo.logOutAlertTitle}
          //       </H2>
          //     </Tappable>
          //   </Link>
          // </div>
        }
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    emailAddress: state.authenticationStore.email,
    userName: state.authenticationStore.userName
  };
}

function mapDispatchToProps(dispatch, props: LogoutProps) {
  return {
    onLogout: () => {
      dispatch(store.authentication.authenticationActions.logout());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);