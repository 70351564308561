//@flow
import type { LineTextFieldProps } from "../line-text-field/LineTextField";
import React from "react";
import { View, Keyboard } from "react-native";
import { IconLineTextField } from "../icon-line-text-field";
import { iconAssets, Icon, iconSizes } from "../icon";
import { H4, AppVersionText } from "../texts";
import { Tappable } from "../tappable";
import { Button } from "../button";
import { connect } from "react-redux";
import * as loginStore from "./store";
import data from "../../data";
import { LoadingIndicator } from "../loading-indicator";
import { NoInternetContainer } from "../no-internet";
import { constants } from "cockpit-shared";
import { LoginHeader } from "../navigation-header";
import { Redirect } from "react-router-dom";
import { InfoManager } from "cockpit-shared/src/data/info";
import { ContextualPage } from "../contextual-page";

export type LoginPageProps = {
  isLoading: boolean,
  login: () => void,
  loginWithMicrosoftAzure: (hash: string) => void,
  onEmailChange: (newEmail: string) => void,
  onPasswordChange: (newPassword: string) => void,
  email: string,
  password: string,
  isEmailValid: boolean,
  isPasswordValid: boolean,
  checkLoginFields: () => Promise<any>,
  navigateToResetPassword: Function,
  isAuthenticated: Boolean
};
export type LoginPageState = {};

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {

  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
      showPrivacyPolicy: false
    };
  }

  onKeyDownHandler = e => {
    //ENTER key Code = 13
    // when user presses ENTER => proceed with login
    if (e.keyCode === 13) {
      this.onLoginPressed();
    }
  };

  get isEmailValid() {
    return this.props.isEmailValid;
  }

  get isPasswordValid() {
    return this.props.isPasswordValid;
  }

  get isLoginFieldsValid() {
    return this.isEmailValid && this.isPasswordValid;
  }

  get emailLineTextFieldProps(): LineTextFieldProps {
    return {
      textFieldProps: {
        placeholder: data.strings.stringsRepo.loginEmailPlaceholder,
        onChangeText: this.props.onEmailChange.bind(this),
        currentValue: this.props.email,
        isValidValue: this.isEmailValid,
      },
    };
  }

  get passwordLineTextFieldProps(): LineTextFieldProps {
    return {
      textFieldProps: {
        isPassword: true,
        placeholder: data.strings.stringsRepo.loginPasswordPlaceholder,
        onChangeText: this.props.onPasswordChange.bind(this),
        currentValue: this.props.password,
        isValidValue: this.isPasswordValid,
      },
    };
  }

  get privacyPolicyLink() {
    let infoManager = new InfoManager();
    return infoManager.getPrivacyPolicyRequest();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDownHandler);
    this.detectHashAndLogin();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDownHandler);
  }

  async onLoginPressed() {
    await this.props.checkLoginFields();
    if (this.isLoginFieldsValid) {
      this.props.login();
    }
    Keyboard.dismiss();
  }

  onLoginWithMicrosoft = () => {
    window.location.href = `https://backend.uszcockpit.ch/saml/login/?redirect=${this.getRedirectUrl()}`;
  }

  getRedirectUrl = () => {
    //Extract the redirect Url, and make sure that there are no queries in it
    let currentHref = window.location.href;
    return currentHref.indexOf("?") === -1 ? currentHref : currentHref.substring(0, currentHref.indexOf("?"));
  }

  detectHashAndLogin = () => {
    const queryString = require("query-string");
    const hash = queryString.parse(this.props.location.search).hash;
    if (hash) {
      this.props.loginWithMicrosoftAzure(hash);
    } else {
      window.location.href = `https://backend.uszcockpit.ch/saml/login/?redirect=${this.getRedirectUrl()}`;
    }
  }

  onResetPasswordPressed() {
    this.props.navigateToResetPassword();
  }

  onPrivacyPress = () => {
    this.setState({
      showPrivacyPolicy: !this.state.showPrivacyPolicy,
    });
  }

  //z-index question see render()
  showHelp = () => {
    this.setState({
      showHelp: !this.state.showHelp,
      showPrivacyPolicy: false,
    });
  }

  get helpContent() {
    return new InfoManager().getHelperRequestForDesktop("login");
  }

  get helpTitle() {
    return data.strings.stringsRepo.helper;
  }

  render() {
    if (this.props.isAuthenticated) {
      const { from } = this.props.location.state || { from: { pathname: "/" } };
      return <Redirect to={from} />;
    }
    return (
      <div id="mainLOGIN"
        tabIndex="0"
        onKeyDown={this.onKeyDownHandler}
        style={{
          flex: 1,
          background: constants.colors.w_blue,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <LoginHeader showHelp={this.showHelp} />
        {this.state.showHelp &&
          // Because of zIndex issues, we need to move ContextualPage to LoginPage; moved from LoginHeader
          <ContextualPage
            content={this.helpContent}
            title={this.helpTitle}
            close={this.showHelp}
          />
        }
        <div className="horisontal-separator" />
        <NoInternetContainer>
          {this.state.showPrivacyPolicy ? (
            <ContextualPage
              content={this.privacyPolicyLink}
              title={data.strings.stringsRepo.privacyPolicy}
              close={this.onPrivacyPress}
            />) : null
          }
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {
              // <View>
              //   <IconLineTextField
              //     isValidValue={this.isEmailValid}
              //     leftIconName={iconAssets.email}
              //     lineTextFieldProps={this.emailLineTextFieldProps}
              //   />
              //   <IconLineTextField
              //     isValidValue={this.isPasswordValid}
              //     style={{ marginTop: constants.dimensions.bigMargin }}
              //     leftIconName={iconAssets.lock}
              //     lineTextFieldProps={this.passwordLineTextFieldProps}
              //   />
              //   <ForgotPassword
              //     onResetPressed={this.onResetPasswordPressed.bind(this)}
              //   />
              //   <PrivacyPolicy onPrivacyPress={this.onPrivacyPress} />
              //   <Button
              //     onPress={this.onLoginPressed.bind(this)}
              //     style={{
              //       marginTop: constants.dimensions.bigMargin,
              //       alignSelf: "center",
              //     }}
              //   >
              //     {data.strings.stringsRepo.loginButton}
              //   </Button>

              //   <Button
              //   onPress={this.onLoginWithMicrosoft}
              //   style={{
              //     marginTop: constants.dimensions.bigMargin,
              //     alignSelf: "center",
              //   }}
              // >
              //   {data.strings.stringsRepo.loginWithMicrosoft}
              // </Button>
              // </View>
            }
          </View>
          <div className="horisontal-separator" />
          <View
            style={{
              height: 80,
              alignSelf: "center",
              justifyContent: "center"
            }}
          >
            <AppVersionText />
          </View>
        </NoInternetContainer>
        <LoadingIndicator loading={this.props.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.loginPageStore.isLoading,
    isEmailValid: state.loginPageStore.isEmailValid,
    isPasswordValid: state.loginPageStore.isPasswordValid,
    email: state.loginPageStore.email,
    password: state.loginPageStore.password,
    isAuthenticated: state.authenticationStore.token !== null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: () => dispatch(loginStore.loginPageActions.login()),
    loginWithMicrosoftAzure: (hash: String) => dispatch(loginStore.loginPageActions.loginWithMicrosoftAzure(hash)),
    onEmailChange: (newEmail: string) =>
      dispatch(loginStore.loginPageActions.onEmailChange(newEmail)),
    onPasswordChange: (newPassword: string) =>
      dispatch(loginStore.loginPageActions.onPasswordChange(newPassword)),
    checkLoginFields: async () =>
      dispatch(loginStore.loginPageActions.checkLoginFields()),
    navigateToResetPassword: () => { },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

const ForgotPassword = (props: { onResetPressed: Function }) => {
  return (
    null
    // <HelperView>
    //   <H4 style={{ color: "white" }}>
    //     {data.strings.stringsRepo.forgotPassword}
    //   </H4>
    //   <Tappable onTap={props.onResetPressed}>
    //     <H4 style={{ color: "white", textDecorationLine: "underline" }}>
    //       {data.strings.stringsRepo.reset}
    //     </H4>
    //   </Tappable>
    // </HelperView>
  );
};

const PrivacyPolicy = (props: { onPrivacyPress: Function }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        marginLeft: constants.dimensions.defaultMargin,
        marginTop: 26,
        marginBottom: 10,
      }}
    >
      <Icon size={iconSizes.BIG} name={iconAssets.check} />
      <View style={{ marginLeft: 13, alignSelf: "center" }}>
        <H4 style={{ color: "white" }}>
          {data.strings.stringsRepo.privacyPolicyDescription}
        </H4>
        <Tappable onTap={props.onPrivacyPress}>
          <H4 style={{ color: "white", textDecorationLine: "underline" }}>
            {data.strings.stringsRepo.privacyPolicy}
          </H4>
        </Tappable>
      </View>
    </View>
  );
};
